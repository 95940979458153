import React from "react";
import Logo from '../components/Logo';
import Navigation from '../components/Navigation';
import { Stock } from "../components/Stock";

const Stocks = () => {
    return (
        <div>
            <Logo />
            <Navigation />

            <Stock />
        </div>
    );
}

export default Stocks;