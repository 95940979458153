import React from 'react';
import Logo from '../components/Logo';
import Navigation from '../components/Navigation';

const About = () => {
    return (
        <div>
            <Logo />
            <Navigation />
            <h1>A propos</h1>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum a, voluptas saepe vitae esse dicta, atque repudiandae accusantium corporis accusamus porro numquam ex. Nostrum quidem laborum vel illum, cupiditate assumenda, voluptates exercitationem quae ullam architecto dignissimos a ea accusantium temporibus? Pariatur deserunt vitae excepturi animi quia repudiandae beatae quas et quibusdam maxime maiores illum aperiam ratione, cumque porro eaque praesentium ullam, sit esse! Soluta rem, voluptas aliquam enim praesentium tempore. Et illum eveniet esse mollitia eius inventore nobis beatae, consectetur in itaque voluptatum! Itaque similique earum nulla ullam dicta voluptatibus, eligendi error consequatur modi necessitatibus consectetur illo iste! Reprehenderit, laudantium!</p>
            <br />
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Enim, iure at minima illo corporis libero culpa modi, impedit hic officiis quasi fugiat. Ullam voluptate delectus dignissimos facilis consectetur totam repudiandae esse laborum deleniti nostrum temporibus provident ex ipsum dolorum quibusdam iusto, eligendi veniam architecto, vero, dolor vel rerum incidunt modi. Quidem rem, voluptatum sed atque assumenda suscipit officiis inventore illum temporibus, dolorum modi obcaecati at vel consequuntur deleniti iure. Quis ipsa perspiciatis eligendi itaque deleniti voluptatem repellat non placeat. Delectus laboriosam dicta porro enim velit at ipsa quos cumque a quasi impedit repellendus, ipsam, voluptatum unde temporibus magnam excepturi, tempora amet sequi nostrum nihil natus modi et. Nisi perferendis at nostrum quam hic officiis consequatur odit maxime distinctio voluptas eaque necessitatibus excepturi obcaecati possimus, atque laudantium, ducimus asperiores. Aliquid nostrum voluptas dolor est dolore cumque nihil iusto cupiditate mollitia voluptatibus aut exercitationem, molestiae ea sint, libero voluptates neque dignissimos eaque ullam sunt minima odit debitis et. Odit laboriosam, quae eius accusantium possimus sapiente unde voluptatibus debitis animi! Facere obcaecati quidem dolorem voluptatem assumenda veritatis iure iste esse ea reiciendis, nam quo odit quia enim eos ab omnis ad distinctio tenetur. Repellendus, veniam mollitia amet doloribus fuga ratione quisquam deserunt eius.</p>
        </div>
    );
};

export default About;