import React from 'react';
import Logo from '../components/Logo';
import Navigation from '../components/Navigation';

const Page404 = () => {
    return (
        <div>
            <Logo />
            <Navigation />
            <h1>Erreur</h1>
            <p>Erreur 404</p>
        </div>
    );
};

export default Page404;